import $ from 'jquery';
import slick from 'slick-carousel';
import 'slick-carousel/slick/slick.css';

$(document).ready(function(){
	$('.c-index-visual-slide').slick({
		arrows: false,
		dots: false,
		autoplay: true,
		autoplaySpeed: 3000,
		fade: false,
		speed: 300,
		swipe: true,
        pauseOnFocus: false,
        pauseOnHover: false,
		responsive: [{
			breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
		  }]
	});
});